
import { defineComponent, computed, onBeforeMount, watch } from "vue";
import { useStore } from "vuex";

import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "Loader",
  props: {
    logo: String,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    onBeforeMount(async () => {
      const store = useStore();
      const isMenuLoaded = computed(() => store.getters.isMenuLoaded);
      if (!isMenuLoaded.value && !route.path.includes("/vt")) {
        await store.dispatch("getMenuFromApi", 0);
      }
      if (!route.path.includes("/vt")) {
        store.dispatch("lookupsStagioniFromApi");
      }
    });

    const loaderType = computed(() => {
      return store.getters.layoutConfig("loader.type");
    });

    return {
      loaderType,
      menuLoaded: computed(() => store.getters.isMenuLoaded),
      statusMenu: computed(() => store.getters.statusMenu),
    };
  },
});
