
import { defineComponent, reactive } from "vue";
import { footerWidthFluid } from "@/core/helpers/config";
// import localhost from "../../axios/dbManag.js";
// import services from "../../axios/dbManag";

export default defineComponent({
  name: "KTFooter",
  setup() {
    const footerMenu = reactive([{}]);
    // services.baseApi.get("menu-footer").then((resp) => {
    //   resp.data.forEach((element) => {
    //     footerMenu.push(element);
    //   });
    // });
    return {
      footerWidthFluid,
      footerMenu,
    };
  },
});
